var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-collapse',{attrs:{"accordion":"","type":"margin"}},_vm._l((_vm.list),function(item,i){return _c('app-collapse-item',{key:item.id,scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('VisitCalendar'))+" ")]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"row-gap":".34rem"}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}}],staticClass:"custom-control-success",attrs:{"name":"check-button","switch":"","title":_vm.$t('TurnOnMyAppointmentCalendar')},on:{"input":function($event){return _vm.updateOneActive(item.id, item.active)},"click":function($event){$event.stopPropagation();}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}}),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteOne(item.id, i)}}},[_c('sw-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]},proxy:true}],null,true)},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"alert-body d-flex align-items-center",staticStyle:{"cursor":"pointer","column-gap":".5rem"},attrs:{"title":_vm.$t('ShareYourCalendar')},on:{"click":function($event){return _vm.copy((_vm.origin + "/booking/" + (item.id)))}}},[_c('sw-icon',{attrs:{"icon":"Share2Icon"}}),_c('p',{staticClass:"h5 mb-0 text-primary"},[_vm._v(" "+_vm._s((_vm.origin + "/booking/" + (item.id)))+" ")])],1)]),_c('validation-observer',{ref:"loginForm",refInFor:true,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',[_c('div',{staticClass:"d-flex mb-25"},[_c('label',{attrs:{"for":"title"}},[_vm._v(" "+_vm._s(_vm.$t('Title'))+" ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-primary",modifiers:{"v-primary":true}}],staticClass:"btn-icon p-25 mt-n25",attrs:{"variant":"flat-primary","title":_vm.$t('YouWillEnterATitleHere'),"size":"sm"}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Title')},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex mb-25"},[_c('label',{attrs:{"for":"description"}},[_vm._v(" "+_vm._s(_vm.$t('Description'))+" ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-primary",modifiers:{"v-primary":true}}],staticClass:"btn-icon p-25 mt-n25",attrs:{"variant":"flat-primary","title":_vm.$t('HereYouWillEnterADescription'),"size":"sm"}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('Description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Description')},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('AvailableDates')}},[_c('b-row',{staticStyle:{"row-gap":".5rem"}},_vm._l((Object.keys(item.availability).sort(function (a, b) { return _vm.daysOfWeek.indexOf(a) - _vm.daysOfWeek.indexOf(b); })),function(day){return _c('b-col',{key:("open_hour_" + day),attrs:{"cols":"12","md":"6","lg":"4"}},[_c('h5',{staticClass:"mb-50"},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":function (e) {
                    if (e) {
                      item.availability[day].start = '8:00'
                      item.availability[day].end = '16:00'
                    }
                  }},model:{value:(item.availability[day].active),callback:function ($$v) {_vm.$set(item.availability[day], "active", $$v)},expression:"item.availability[day].active"}},[_vm._v(" "+_vm._s(_vm.$t(("days." + day)))+" ")])],1),(item.availability[day].active)?_c('b-row',{key:("open_hour_" + day + "_1")},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-0"},[_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"disabled":!item.availability[day].active,"type":"text","placeholder":"HH:mm"},model:{value:(item.availability[day].start),callback:function ($$v) {_vm.$set(item.availability[day], "start", $$v)},expression:"item.availability[day].start"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"size":"sm","button-variant":"outline-primary","button-only":"","label-close-button":_vm.$t('Close'),"label-no-time-selected":_vm.$t('NoTimeSelected'),"disabled":!item.availability[day].active,"value":item.availability[day].start},on:{"input":function (e) {
                            var ref = e.split(':');
                            var h = ref[0];
                            var m = ref[1];
                            item.availability[day].start = h + ":" + m
                          }}})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-0"},[_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"disabled":!item.availability[day].active,"type":"text","placeholder":"HH:mm"},model:{value:(item.availability[day].end),callback:function ($$v) {_vm.$set(item.availability[day], "end", $$v)},expression:"item.availability[day].end"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"size":"sm","button-variant":"outline-primary","button-only":"","label-close-button":_vm.$t('Close'),"label-no-time-selected":_vm.$t('NoTimeSelected'),"disabled":!item.availability[day].active,"value":item.availability[day].end},on:{"input":function (e) {
                            var ref = e.split(':');
                            var h = ref[0];
                            var m = ref[1];
                            _vm.ite.availability[day].end = h + ":" + m
                          }}})],1)],1)],1)],1)],1):_vm._e(),(!item.availability[day].active)?_c('div',{key:("open_hour_" + day + "_2"),staticClass:"bg-light-primary rounded p-50 text-center",attrs:{"variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_c('strong',[_vm._v(_vm._s(_vm.$t(("days." + day))))]),_vm._v(" - "+_vm._s(_vm.$t('Closed'))+" ")])]):_vm._e()],1)}),1)],1),_c('h4',[_vm._v(" "+_vm._s(_vm.$t('Options'))+" ")]),_c('b-form-group',{attrs:{"label":((_vm.$t('Policy')) + " (URL)")}},[_c('validation-provider',{attrs:{"name":((_vm.$t('Policy')) + " (URL)")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null,"placeholder":((_vm.$t('Policy')) + " (URL)")},model:{value:(item.acceptUrl),callback:function ($$v) {_vm.$set(item, "acceptUrl", $$v)},expression:"item.acceptUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","size":"sm","disabled":invalid || _vm.loading},on:{"click":function($event){return _vm.updateOne(item)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)]}}],null,true)}),_c('sw-info-overlay',{attrs:{"show":_vm.globalLoading},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('b-spinner',{staticStyle:{"margin-top":".3rem"},attrs:{"variant":"primary","small":""}})]},proxy:true}],null,true)})],1)}),1),_c('b-alert',{staticClass:"mt-1",attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body py-4 d-flex flex-column align-items-center justify-content-center"},[_c('h3',{staticClass:"text-primary mb-1"},[_vm._v(" "+_vm._s(_vm.$t('AddNewBookingCalendar'))+" ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.addNew()}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }