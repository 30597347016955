<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >
      <app-collapse-item
        v-for="(item, i) in list"
        :key="item.id"
      >
        <template #header>
          <h4 class="mb-0">
            {{ $t('VisitCalendar') }}
          </h4>

          <div
            class="d-flex align-items-center"
            style="row-gap: .34rem"
          >
            <b-form-checkbox
              v-model="item.active"
              v-b-tooltip.hover.v-success
              class="custom-control-success"
              name="check-button"
              switch
              :title="$t('TurnOnMyAppointmentCalendar')"
              @input="updateOneActive(item.id, item.active)"
              @click.stop=""
            />

            <b-button
              variant="flat-danger"
              size="sm"
              class="btn-icon"
              @click.stop="deleteOne(item.id, i)"
            >
              <sw-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>

        <b-alert
          variant="primary"
          show
        >
          <div
            v-b-tooltip.hover.v-primary
            class="alert-body d-flex align-items-center"
            :title="$t('ShareYourCalendar')"
            style="cursor: pointer; column-gap: .5rem;"
            @click="copy(`${origin}/booking/${item.id}`)"
          >
            <sw-icon icon="Share2Icon" />

            <p class="h5 mb-0 text-primary">
              {{ `${origin}/booking/${item.id}` }}
            </p>
          </div>
        </b-alert>

        <validation-observer
          ref="loginForm"
          v-slot="{invalid}"
        >
          <!--    START::Title    -->
          <b-form-group>
            <div class="d-flex mb-25">
              <label for="title">
                {{ $t('Title') }}
              </label>

              <b-button
                v-b-tooltip.v-primary
                variant="flat-primary"
                :title="$t('YouWillEnterATitleHere')"
                class="btn-icon p-25 mt-n25"
                size="sm"
              >
                <feather-icon icon="AlertCircleIcon" />
              </b-button>
            </div>

            <validation-provider
              v-slot="{ errors }"
              :name="$t('Title')"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="item.title"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Title')"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--    END::Title    -->

          <!--    START::Description    -->
          <b-form-group>
            <div class="d-flex mb-25">
              <label for="description">
                {{ $t('Description') }}
              </label>

              <b-button
                v-b-tooltip.v-primary
                variant="flat-primary"
                :title="$t('HereYouWillEnterADescription')"
                class="btn-icon p-25 mt-n25"
                size="sm"
              >
                <feather-icon icon="AlertCircleIcon" />
              </b-button>
            </div>

            <validation-provider
              v-slot="{ errors }"
              :name="$t('Description')"
              rules="required"
            >
              <b-form-textarea
                id="description"
                v-model="item.description"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Description')"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--    END::Description    -->

          <!--    START::Dates    -->
          <b-form-group :label="$t('AvailableDates')">
            <b-row style="row-gap: .5rem">
              <b-col
                v-for="day in Object.keys(item.availability).sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))"
                :key="`open_hour_${day}`"
                cols="12"
                md="6"
                lg="4"
              >
                <h5 class="mb-50">
                  <b-form-checkbox
                    v-model="item.availability[day].active"
                    switch
                    @change="(e) => {
                      if (e) {
                        item.availability[day].start = '8:00'
                        item.availability[day].end = '16:00'
                      }
                    }"
                  >
                    {{ $t(`days.${day}`) }}
                  </b-form-checkbox>
                </h5>

                <b-row
                  v-if="item.availability[day].active"
                  :key="`open_hour_${day}_1`"
                >
                  <b-col
                    sm="12"
                    md="6"
                  >
                    <b-form-group class="mb-0">
                      <b-input-group class="mb-1">
                        <b-form-input
                          v-model="item.availability[day].start"
                          :disabled="!item.availability[day].active"
                          type="text"
                          placeholder="HH:mm"
                        />
                        <b-input-group-append>
                          <b-form-timepicker
                            size="sm"
                            button-variant="outline-primary"
                            button-only
                            :label-close-button="$t('Close')"
                            :label-no-time-selected="$t('NoTimeSelected')"
                            :disabled="!item.availability[day].active"
                            :value="item.availability[day].start"
                            @input="(e) => {
                              const [h, m] = e.split(':')
                              item.availability[day].start = `${h}:${m}`
                            }"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                  >
                    <b-form-group class="mb-0">
                      <b-input-group class="mb-1">
                        <b-form-input
                          v-model="item.availability[day].end"
                          :disabled="!item.availability[day].active"
                          type="text"
                          placeholder="HH:mm"
                        />
                        <b-input-group-append>
                          <b-form-timepicker
                            size="sm"
                            button-variant="outline-primary"
                            button-only
                            :label-close-button="$t('Close')"
                            :label-no-time-selected="$t('NoTimeSelected')"
                            :disabled="!item.availability[day].active"
                            :value="item.availability[day].end"
                            @input="(e) => {
                              const [h, m] = e.split(':')
                              ite.availability[day].end = `${h}:${m}`
                            }"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div
                  v-if="!item.availability[day].active"
                  :key="`open_hour_${day}_2`"
                  class="bg-light-primary rounded p-50 text-center"
                  variant="primary"
                >
                  <div class="alert-body">
                    <strong>{{ $t(`days.${day}`) }}</strong> - {{ $t('Closed') }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <!--    END::Dates    -->

          <!--    START::Options    -->
          <h4>
            {{ $t('Options') }}
          </h4>

          <!--    START::Accept URL    -->
          <b-form-group :label="`${$t('Policy')} (URL)`">
            <validation-provider
              v-slot="{ errors }"
              :name="`${$t('Policy')} (URL)`"
            >
              <b-form-input
                id="title"
                v-model="item.acceptUrl"
                :state="errors.length > 0 ? false:null"
                :placeholder="`${$t('Policy')} (URL)`"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--    END::Accept URL    -->
          <!--    END::Options    -->

          <b-button
            variant="primary"
            type="submit"
            size="sm"
            :disabled="invalid || loading"
            @click="updateOne(item)"
          >
            <b-spinner
              v-if="loading"
              small
            />

            <span v-else>
              {{ $t('Save') }}
            </span>
          </b-button>
        </validation-observer>

        <sw-info-overlay :show="globalLoading">
          <template #icon>
            <b-spinner
              variant="primary"
              small
              style="margin-top: .3rem"
            />
          </template>
        </sw-info-overlay>
      </app-collapse-item>
    </app-collapse>

    <b-alert
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body py-4 d-flex flex-column align-items-center justify-content-center">
        <h3 class="text-primary mb-1">
          {{ $t('AddNewBookingCalendar') }}
        </h3>

        <b-button
          variant="primary"
          :disabled="loading"
          @click="addNew()"
        >
          <b-spinner
            v-if="loading"
            small
          />

          <span v-else>
            {{ $t('Add') }}

            <feather-icon icon="PlusIcon" />
          </span>
        </b-button>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BFormTextarea, BFormTimepicker, BInputGroupAppend, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import {
  ADD_BOOKING, DELETE_BOOKING, GET_BOOKING, GET_BOOKING_ALL, UPDATE_BOOKING,
} from '@/@constants/mutations'

export default {
  components: {
    BInputGroupAppend,
    BFormTimepicker,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    globalLoading: false,
    loading: false,

    daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],

    list: [],

    newItem: {
      active: false,

      title: '',
      description: '',

      availability: {
        MONDAY: {
          active: true,
          start: '8:00',
          end: '16:00',
        },
        TUESDAY: {
          active: true,
          start: '8:00',
          end: '16:00',
        },
        WEDNESDAY: {
          active: true,
          start: '8:00',
          end: '16:00',
        },
        THURSDAY: {
          active: true,
          start: '8:00',
          end: '16:00',
        },
        FRIDAY: {
          active: true,
          start: '8:00',
          end: '16:00',
        },
        SATURDAY: {
          active: true,
          start: '8:00',
          end: '14:00',
        },
        SUNDAY: {
          active: false,
          start: '8:00',
          end: '16:00',
        },
      },

      acceptUrl: '',
    },

    flatPickerConfig: {
      locale: [pl, en],
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
    },

    // Use d1 on dev mode
    origin: process.env.NODE_ENV === 'development' ? 'https://d1.saleswizardapp.com' : window.location.origin,
  }),

  mounted() {
    this.ini()
  },

  methods: {
    async ini() {
      await this.load()
    },

    async load() {
      this.globalLoading = true

      const { items } = await this.$store.dispatch(`profileSettings/${GET_BOOKING_ALL}`)

      this.list.push(...items)

      this.globalLoading = false
    },

    async loadOne(id) {
      if (id) {
        this.globalLoading = true

        const { item } = await this.$store.dispatch(`profileSettings/${GET_BOOKING}`, id)

        if (item) this.list.push(item)

        this.globalLoading = false
      }
    },

    async updateOne(item) {
      this.loading = true

      const payload = { ...item }

      delete payload.user

      await this.$store.dispatch(`profileSettings/${UPDATE_BOOKING}`, payload)

      this.loading = false
    },

    async updateOneActive(id, active) {
      this.loading = true

      await this.$store.dispatch(`profileSettings/${UPDATE_BOOKING}`, { id, active })

      this.loading = false
    },

    async addNew() {
      this.loading = true

      const item = await this.$store.dispatch(`profileSettings/${ADD_BOOKING}`, this.newItem)

      if (item) {
        this.list.push({ id: item.id, ...this.newItem })

        this.showToast('success', this.$i18n.t('CalendarAdded'))
      }

      this.loading = false
    },

    async deleteOne(id, index) {
      this.loading = true

      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(async () => {
          const isOk = await this.$store.dispatch(`profileSettings/${DELETE_BOOKING}`, id)

          if (isOk) this.list.splice(index, 1)
        })
        .catch(() => { })

      this.loading = false
    },

    copy(txt) {
      this.$copyText(txt, this.$refs.contact_container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
  },
}
</script>
